import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Autoplay, Scrollbar } from 'swiper/modules';

// Reusable Swiper Component with Responsive Layout
const CustomSwiper = ({ slides }) => (
  <Swiper
    slidesPerView={1}
    centeredSlides={false}
    slidesPerGroupSkip={1}
    grabCursor={true}
    autoplay={{ delay: 4000, disableOnInteraction: false }} // Set delay to 4000 ms (4 seconds)
    scrollbar={{ draggable: true }}
    modules={[Autoplay, Scrollbar]} // Include only Autoplay and Scrollbar modules
    className="mySwiper"
  >
    {slides.map((slide, index) => (
      <SwiperSlide key={index}>
        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-center lg:px-3 py-4 md:py-6">
          {/* Content Section */}
          <div className="flex-1 lg:justify-center md:justify-start md:text-left mb-6 lg:mb-0">
            <div className='max-w-md'>
              <h1 className="md:text-[32px] text-[22px] mb-2 md:mb-5">{slide.heading}</h1>
              <p className="text-base text-gray-700">{slide.description}</p>
            </div>
          </div>
          {/* Image Section */}
          <div className="lg:flex lg:items-center lg:justify-center w-full md:w-auto">
            <img src={slide.image} alt={`Slide ${index + 1}`} className="w-full md:h-[400px] md:max-w-full rounded-md" />
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
);

export default CustomSwiper;
