import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../utils/blogData";

const Blogs = () => {
  return (
    <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
      <div className="lg:text-center">
        <h3 className="md:text-[40px] text-[28px] font-normal leading-8 mb-8">
          Our Blogs
        </h3>
      </div>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {blogData.map((blog) => (
          <Link key={blog.id} to={`/blogs/${blog.slug}`} target="_blank">
            <div className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-left shadow-md border relative transition-all ease-linear duration-500 hover:shadow-lg hover:-translate-y-2 ${blog.blog_border}`}>
              <img
                alt={blog.alt}
                src={blog.image}
                className="rounded-t-lg h-[200px] object-cover"
              />
              <p className="text-[12px] font-medium p-[13px]">{blog.title}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
