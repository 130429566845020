const productSpecs = {
  '65"': [
    { label: "Resolution", value: "4K UHD (3840*2160)" },
    { label: "Aspect Ratio", value: "16:9" },
    { label: "Brightness(nits)", value: "400" },
    { label: "Contrast Ratio(typ)", value: "1300:1" },
    { label: "Response Time", value: "8ms (typ)" },
    { label: "Viewing Angle", value: "178° H/V" },
    {
      label: "Surface Protection",
      value: "4mm  Toughened Anti-Glare Glass with Level 7 of MOHS Standard",
    },
    { label: "Touch Technology", value: "Infrared" },
    { label: "Touch Points", value: "20" },
    { label: "Touch Precision Accuracy", value: "±1mm" },
    { label: "Processor", value: "Octa Core Cortex, 8*A55, 1.5 GHz" },
    { label: "RAM/ROM", value: "8GB/128GB,12GB/128GB/256GB" },
    { label: "Operating System", value: "Android 14/Windows (Optional)" },
    {
      label: "Ports & Connectivity",
      value:
        "HDMI (IN) x2, USB 3.0x3, USB TOUCH, USB Type-C, AV (IN), AV (OUT), DP (IN), VGA (IN), YPbPr (IN), RS232 (IN), PC/VGA Audio (IN), Earphone (OUT), SPDIF/Digital Audio (OUT), R145, In-Built Dual Band Wi-Fi, HDMI (OUT), MIC (IN)",
    },
    { label: "Mic", value: "8 x far-field, Beam-forming mic, Max 8m range" },
    { label: "Camera", value: "48MP, Room-framing" },
    { label: "Speaker Output", value: "2*20W" },
    { label: "Screen Mirroring", value: "Yes" },
    { label: "Whiteboard Pen", value: "2" },
    { label: "Remote Control", value: "1" },
    { label: "Wall Mount Bracket", value: "Yes" },
    { label: "Power Supply (AC)", value: "100-240V" },
    { label: "Power Consumption", value: "≤180W" },
    { label: "Product Dimensions", value: "1486 x 909 x 89.9" },
    { label: "Net Weight", value: "40kg" },
    { label: "Gross Weight", value: "54kg" },
    { label: "Warranty", value: "3 Years" },
  ],
  '75"': [
    { label: "Resolution", value: "4K UHD (3840*2160)" },
    { label: "Aspect Ratio", value: "16:9" },
    { label: "Brightness(nits)", value: "400" },
    { label: "Contrast Ratio(typ)", value: "1300:1" },
    { label: "Response Time", value: "8ms (typ)" },
    { label: "Viewing Angle", value: "178° H/V" },
    {
      label: "Surface Protection",
      value: "4mm  Toughened Anti-Glare Glass with Level 7 of MOHS Standard",
    },
    { label: "Touch Technology", value: "Infrared" },
    { label: "Touch Points", value: "20" },
    { label: "Touch Precision Accuracy", value: "±1mm" },
    { label: "Processor", value: "Octa Core Cortex, 8*A55, 1.5 GHz" },
    { label: "RAM/ROM", value: "8GB/128GB,12GB/128GB/256GB" },
    { label: "Operating System", value: "Android 14/Windows (Optional)" },
    {
      label: "Ports & Connectivity",
      value:
        "HDMI (IN) x2, USB 3.0x3, USB TOUCH, USB Type-C, AV (IN), AV (OUT), DP (IN), VGA (IN), YPbPr (IN), RS232 (IN), PC/VGA Audio (IN), Earphone (OUT), SPDIF/Digital Audio (OUT), R145, In-Built Dual Band Wi-Fi, HDMI (OUT), MIC (IN)",
    },
    { label: "Mic", value: "8 x far-field, Beam-forming mic, Max 8m range" },
    { label: "Camera", value: "48MP, Room-framing" },
    { label: "Speaker Output", value: "2*20W" },
    { label: "Screen Mirroring", value: "Yes" },
    { label: "Whiteboard Pen", value: "2" },
    { label: "Remote Control", value: "1" },
    { label: "Wall Mount Bracket", value: "Yes" },
    { label: "Power Supply (AC)", value: "100-240V" },
    { label: "Power Consumption", value: "≤200W" },
    { label: "Product Dimensions", value: "1707.16 x 1033.62 x 100.20" },
    { label: "Net Weight", value: "54Kg" },
    { label: "Gross Weight", value: "71Kg" },
    { label: "Warranty", value: "3 Years" },
  ],
  '86"': [
    { label: "Resolution", value: "4K UHD (3840*2160)" },
    { label: "Aspect Ratio", value: "16:9" },
    { label: "Brightness(nits)", value: "400" },
    { label: "Contrast Ratio(typ)", value: "1300:1" },
    { label: "Response Time", value: "8ms (typ)" },
    { label: "Viewing Angle", value: "178° H/V" },
    {
      label: "Surface Protection",
      value: "4mm  Toughened Anti-Glare Glass with Level 7 of MOHS Standard",
    },
    { label: "Touch Technology", value: "Infrared" },
    { label: "Touch Points", value: "20" },
    { label: "Touch Precision Accuracy", value: "±1mm" },
    { label: "Processor", value: "Octa Core Cortex, 8*A55, 1.5 GHz" },
    { label: "RAM/ROM", value: "8GB/128GB,12GB/128GB/256GB" },
    { label: "Operating System", value: "Android 14/Windows (Optional)" },
    {
      label: "Ports & Connectivity",
      value:
        "HDMI (IN) x2, USB 3.0x3, USB TOUCH, USB Type-C, AV (IN), AV (OUT), DP (IN), VGA (IN), YPbPr (IN), RS232 (IN), PC/VGA Audio (IN), Earphone (OUT), SPDIF/Digital Audio (OUT), R145, In-Built Dual Band Wi-Fi, HDMI (OUT), MIC (IN)",
    },
    { label: "Mic", value: "8 x far-field, Beam-forming mic, Max 8m range" },
    { label: "Camera", value: "48MP, Room-framing" },
    { label: "Speaker Output", value: "2*20W" },
    { label: "Screen Mirroring", value: "Yes" },
    { label: "Whiteboard Pen", value: "2" },
    { label: "Remote Control", value: "1" },
    { label: "Wall Mount Bracket", value: "Yes" },
    { label: "Power Supply (AC)", value: "100-240V" },
    { label: "Power Consumption", value: "≤220W" },
    { label: "Product Dimensions", value: "1965 x 1179 x 99" },
    { label: "Net Weight", value: "68Kg" },
    { label: "Gross Weight", value: "81Kg" },
    { label: "Warranty", value: "3 Years" },
  ],
  '98"': [
    { label: "Resolution", value: "4K UHD (3840*2160)" },
    { label: "Aspect Ratio", value: "16:9" },
    { label: "Brightness(nits)", value: "400" },
    { label: "Contrast Ratio(typ)", value: "1300:1" },
    { label: "Response Time", value: "8ms (typ)" },
    { label: "Viewing Angle", value: "178° H/V" },
    {
      label: "Surface Protection",
      value: "4mm  Toughened Anti-Glare Glass with Level 7 of MOHS Standard",
    },
    { label: "Touch Technology", value: "Infrared" },
    { label: "Touch Points", value: "20" },
    { label: "Touch Precision Accuracy", value: "±1mm" },
    { label: "Processor", value: "Octa Core Cortex, 8*A55, 1.5 GHz" },
    { label: "RAM/ROM", value: "8GB/128GB,12GB/128GB/256GB" },
    { label: "Operating System", value: "Android 14/Windows (Optional)" },
    {
      label: "Ports & Connectivity",
      value:
        "HDMI (IN) x2, USB 3.0x3, USB TOUCH, USB Type-C, AV (IN), AV (OUT), DP (IN), VGA (IN), YPbPr (IN), RS232 (IN), PC/VGA Audio (IN), Earphone (OUT), SPDIF/Digital Audio (OUT), R145, In-Built Dual Band Wi-Fi, HDMI (OUT), MIC (IN)",
    },
    { label: "Mic", value: "8 x far-field, Beam-forming mic, Max 8m range" },
    { label: "Camera", value: "48MP, Room-framing" },
    { label: "Speaker Output", value: "2*20W" },
    { label: "Screen Mirroring", value: "Yes" },
    { label: "Whiteboard Pen", value: "2" },
    { label: "Remote Control", value: "1" },
    { label: "Wall Mount Bracket", value: "Yes" },
    { label: "Power Supply (AC)", value: "100-240V" },
    { label: "Power Consumption", value: "≤300W" },
    { label: "Product Dimensions", value: "2232 x 1329.4 x 108.1" },
    { label: "Net Weight", value: "180Kg" },
    { label: "Gross Weight", value: "205Kg" },
    { label: "Warranty", value: "3 Years" },
  ],
};

export default productSpecs;
