import React from "react";
import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import CTA from "../../../components/CTA";
import Footer from "../../../components/Footer";
import ScrollToTopButton from "../../../components/ScrollTop";
import ProductSpecification from "../../../components/ProductSpecification";
import ImageContentLayout from "../../../components/ImageContentLayout";
import productSpecs from "../../../utils/inspireSeries";
import ApplicationScenarios from "../../../components/ApplicationScenarios";
import Blogs from "../../../components/Blogs";
import SEO from "../../../components/SEO";
import Testimonials from "../../../components/Testimonials";

const testimonials = [
  {
    image: "/assets/images/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "/assets/images/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "/assets/images/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "/assets/images/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "/assets/images/testimonials/berkadia.jpg",
    name: "Raj Kumar",
    designation: "AV Head",
    description:
      "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
  },
  {
    image: "/assets/images/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "/assets/images/testimonials/edify-education.png",
    name: "Kishore Pandit",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
  {
    image: "/assets/images/testimonials/the-dollar-business.png",
    name: "Ashok Tripathi",
    designation: "IT Head",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
];

const images = [
  {
    url: "/assets/images/products/rise-large-conference-room.jpg",
    name: "Large area Conference Room",
  },
  {
    url: "/assets/images/products/rise-corporate-training-room.jpg",
    name: "Corporate Training Room",
  },
  {
    url: "/assets/images/products/rise-conference-room.jpg",
    name: "Conference Room",
  },
  {
    url: "/assets/images/products/rise-classroom.jpg",
    name: "Class Room",
  },
  {
    url: "/assets/images/products/rise-huddle-room.jpg",
    name: "Huddle Room",
  },
];

const contentBlock1 = (
  <>
    <div>
      <p className="text-2xl font-medium mb-5">Ultra-Clear 4K Display</p>
      <p className="text-sm font-normal">
        Experience stunning 4K clarity and vibrant 400-nit brightness from any
        angle. With toughened glass protection, our display delivers unmatched
        durability and breathtaking visuals—perfect for corporate & education
        spaces.
      </p>
      <div className="grid grid-cols-2 pt-[60px]">
        <div className="mb-5">
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            4K
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            100% NTSC Colour Gamut Display
          </span>
        </div>
        <div className="text-right mb-5">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            400 nits
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Brightness
          </span>
        </div>
        <div>
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            178°
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Viewing Angle
          </span>
        </div>
        <div className="text-right">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            Toughened
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Glass Screen
          </span>
        </div>
      </div>
    </div>
  </>
);
const contentBlock2 = (
  <>
    <div>
      <p className="text-2xl font-medium mb-5">
        Precision Infrared Touch Technology
      </p>
      <p className="text-sm font-normal">
        Engage effortlessly with our infrared touch technology—20 touch points
        for seamless collaboration and ±1mm precision for unmatched accuracy.
        Every tap and swipe is flawlessly responsive.
      </p>
      <div className="grid grid-cols-2 pt-[60px]">
        <div className="mb-5">
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            Infrared
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Touch Technology
          </span>
        </div>
        <div className="text-right mb-5">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            20
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Touch Points
          </span>
        </div>
        <div>
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            Precision
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Writing
          </span>
        </div>
        <div className="text-right">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            ±1mm
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Touch Precision
          </span>
        </div>
      </div>
    </div>
  </>
);
const contentBlock3 = (
  <>
    <div>
      <p className="text-2xl font-medium mb-5">
        Powerful Performance & Connectivity
      </p>
      <p className="text-sm font-normal">
        Boost performance with our Octa-Core Cortex processor, 12GB RAM, and
        256GB ROM. Enjoy lightning-fast speeds, versatile ports, and dual OS
        support (Android 14 and Windows) for effortless multitasking and
        connectivity.
      </p>
      <div className="grid grid-cols-2 pt-[60px]">
        <div className="mb-5">
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            Octa-Core
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Processor
          </span>
        </div>
        <div className="text-right mb-5">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            15
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Connection Ports
          </span>
        </div>
        <div>
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            12/256 GB
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            RAM/ROM atmost
          </span>
        </div>
        <div className="text-right">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            Dual
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Operating Systems
          </span>
        </div>
      </div>
    </div>
  </>
);
const contentBlock4 = (
  <>
    <div>
      <p className="text-2xl font-medium mb-5">
        Advance Audio-Visual Experience
      </p>
      <p className="text-sm font-normal">
        An 8-array mic with an 8-meter range ensures crystal-clear voice pickup,
        while dual speakers deliver immersive sound—perfect for any space.
      </p>
      <div className="grid grid-cols-2 pt-[60px]">
        <div className="mb-5">
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            48MP
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Room Framing Camera
          </span>
        </div>
        <div className="text-right mb-5">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            20W
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Dual Speakers
          </span>
        </div>
        <div className="invisible">
          <p className="text-pink-grd text-[30px] font-semibold not-italic">
            12/256 GB
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            RAM/ROM atmost
          </span>
        </div>
        <div className="text-right">
          <p className="text-orange-grd text-[30px] font-semibold not-italic">
            8
          </p>
          <span className="text-sm font-normal not-italic text-gray-800">
            Array Mic
          </span>
        </div>
      </div>
    </div>
  </>
);
const InspireSeries = () => {
  return (
    <>
      <div>
        <SEO
          title="Inspire Series - Interactive Flat Panel | Revolutionize Collaboration and Learning | Coltec Global"
          description="Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces."
          keywords="Inspire Series, Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Interactive Flat Panel Display, Smart Board for Classrooms, Education Technology Solutions, Best Interactive Flat Panel for Schools, Touch Screen Display for Collaborative Learning, Interactive Smart Board for Online Teaching, High-Resolution Interactive Display for Business Meetings, Top Interactive Flat Panels for Classrooms, Multi-Touch Capability Interactive Panel, Zero Bonding Touch Technology Display, Integrated Software Solutions for Teaching, Interactive LED Panel with Low Power Consumption, Durable Interactive Display with Anti-Glare Technology, Interactive Panels for Hybrid Classrooms, Interactive Whiteboard for Conference Rooms, Digital Signage and Interactive Displays for Offices, Interactive Flat Panels for Remote Learning, Educational Interactive Touch Screen Panels, Interactive Flat Panel Supplier in India, Smart Classroom Displays for Indian Schools"
          ogTitle="Inspire Series - Interactive Flat Panel | Revolutionize Collaboration and Learning | Coltec Global"
          ogDescription="Empower classrooms with the Inspire Series Interactive Flat Panel, offering innovative features and intuitive technology for enhanced collaboration and creativity."
          ogImage="/assets/images/products/inspire-banner-image.jpg"
          ogUrl="https://coltec-global.com/products/interactive-flat-panel/inspire-series"
          canonicalUrl="https://coltec-global.com/products/interactive-flat-panel/inspire-series"
        />
        <Header />
        <Banner
          imgSrc="/assets/images/products/inspire-banner-image.jpg"
          altText="Background image"
          headerText="INSPIRE SERIES"
          bannerHeight="md:h-dvh h-[60vh]"
          imgHeight="h-full lg:h-auto w-full"
          positionClasses="absolute md:top-[40%] bottom-20 left-0 lg:left-0 lg:right-0 text-center"
          headerTextClasses="xl:text-[75px] xl:max-w-[240px] md:text-[44px] text-[36px] W-14 font-bold not-italic text-[#774D42] leading-2 md:leading-[65px]"
          paragraphTextClasses="md:text-[20px] text-base not-italic font-normal md:leading-[30px] mt-4 text-white max-w-[664px]"
          paragraphText="Revolutionize Collaboration and Learning"
          paragraphTextThree="Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces."
          paragraphTextThreeClasses="max-w-[450px] text-[13px] font-normal text-white"
        />
        <div className="bg-[#F5F5F5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-4xl m-auto">
            <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 xl:gap-14 md:gap-8 gap-6">
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="center-center"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="color-wheel"
                    src="/assets/images/products/color-wheel.svg"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    100% NTSC
                  </h3>
                  <p className="text-[#BAAC63] mt-1 md:text-sm text-[12px] not-italic leading-5">
                    colour gamut
                  </p>
                </div>
              </div>
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="center-center"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="intelligence"
                    src="/assets/images/products/intelligence.svg"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    Intelligent
                  </h3>
                  <p className="text-[#BAAC63] mt-1 md:text-sm text-[12px] not-italic leading-5">
                    touch point
                  </p>
                </div>
              </div>
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="center-center"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="dual"
                    src="/assets/images/products/dual.svg"
                    className="h-9"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    Dual
                  </h3>
                  <p className="text-[#BAAC63] mt-1 md:text-sm text-[12px] not-italic leading-5">
                    stereo speakers
                  </p>
                </div>
              </div>
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="dual-os"
                    src="/assets/images/products/dual-os.svg"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    Dual
                  </h3>
                  <p className="text-[#BAAC63] mt-1 md:text-sm text-[12px] not-italic leading-5">
                    Operating Systems
                  </p>
                </div>
              </div>
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="array-mic-icon"
                    src="/assets/images/products/array-mic-icon.svg"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    8-Array
                  </h3>
                  <p className="text-[#BAAC63] mt-1 md:text-sm text-[12px] not-italic leading-5">
                    Built-in Microphone
                  </p>
                </div>
              </div>
              <div
                className="bg-white rounded-xl shadow-md p-6"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <div className="flex justify-center items-center flex-col">
                  <img
                    alt="camera-icon"
                    src="/assets/images/products/camera-icon.svg"
                  />
                  <h3 className="text-[#BAAC63] text-[18px] md:text-[24px] not-italic font-bold leading-7 mt-4">
                    48MP
                  </h3>
                  <p className="text-[#BAAC63] text-center mt-1 md:text-sm text-[12px] not-italic leading-5">
                    Room framing Camera
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F5F5F5]">
          <div className="px-6 lg:px-8 lg:py-14 pb-10 max-w-7xl m-auto">
            <ImageContentLayout
              imageSrc="/assets/images/products/inspire-ultra-clear-4k-display.jpg"
              content={contentBlock1}
              imagePosition="right"
            />
          </div>
        </div>
        <div className="bg-white">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <ImageContentLayout
              imageSrc="/assets/images/products/inspire-precision-infrared-touch-tech.jpg"
              content={contentBlock2}
              imagePosition="left"
            />
          </div>
        </div>
        <div className="bg-[#F5F5F5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <ImageContentLayout
              imageSrc="/assets/images/products/inspire-powerful-performance-connectivity.jpg"
              content={contentBlock3}
              imagePosition="right"
            />
          </div>
        </div>
        <div className="bg-white">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <ImageContentLayout
              imageSrc="/assets/images/products/inspire-advance-audio-visual.jpg"
              content={contentBlock4}
              imagePosition="left"
            />
          </div>
        </div>
        <div className="bg-[#f5f5f5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <ApplicationScenarios images={images} />
          </div>
        </div>
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <ProductSpecification specs={productSpecs} />
        </div>
        <div className="bg-[#f5f5f5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <Testimonials testimonials={testimonials} />
          </div>
        </div>
        <div>
          <Blogs />
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default InspireSeries;
