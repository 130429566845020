import React from 'react'
const clientImages = [
    '/assets/images/home/clients/pepsi-co-logo.svg',
    '/assets/images/home/clients/new-york-life.svg',
    '/assets/images/home/clients/ford-logo.svg',
    '/assets/images/home/clients/monash-university-logo.svg',
    '/assets/images/home/clients/oracle-logo.svg',
    '/assets/images/home/clients/johnson-and-johnson-logo.svg',
    '/assets/images/home/clients/cquniversity-logo.svg',
    '/assets/images/home/clients/avondale-university-college-logo.svg',
    '/assets/images/home/clients/walmart-logo.svg',
    '/assets/images/home/clients/the-university-of-melbourne-logo.svg',
    '/assets/images/home/clients/rkt-big-logo.svg',
    '/assets/images/home/clients/pepsi-co-logo.svg',
    '/assets/images/home/clients/new-york-life.svg',
    '/assets/images/home/clients/ford-logo.svg',
    '/assets/images/home/clients/monash-university-logo.svg',
    '/assets/images/home/clients/oracle-logo.svg',
  ];
const Clients = () => {
  return (
    <>
    <div className='max-w-7xl mx-auto px-6 lg:px-8 lg:py-14 py-10'>
        <div className='lg:text-center'>
            <h3 className='md:text-[40px] text-[28px] font-normal leading-8 mb-8'>Our Clients</h3>
        </div>
     <div>
      <div className='overflow-hidden'>
      <div className="relative flex justify-between">
        <div className='contents'>
          <div className='slide-animation-logos flex'>
          {clientImages.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Client ${index}`}
              loading="lazy" // Enable lazy loading
              className="h-20 w-32 mx-4"
            />
          ))}
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
    </>
  )
}

export default Clients