import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import CTA from "../../components/CTA";
import OurClients from "../../components/OurClients";
import CorporateModule from "../../components/CorporateSwiper";
import ScrollToTopButton from "../../components/ScrollTop";
import Blogs from "../../components/Blogs";
import SEO from "../../components/SEO";
import Testimonials from "../../components/Testimonials";
const testimonials = [
  {
    image: "/assets/images/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "/assets/images/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "/assets/images/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "/assets/images/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "/assets/images/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "/assets/images/testimonials/edify-education.png",
    name: "Kishore Pandit",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
];
const cslides = [
  {
    heading: "Writing Board",
    description:
      "COLTEC’s IWB-Integrated Writing Board enhances your writing experience with quick tools and shortcuts to make your writing smooth, effective, and fast. Enjoy the Power of Endless Colour & Content full Canvas​.",
    image: "/assets/images/education/endless-whiteboarding.jpg",
  },
  {
    heading: "Smart Tools",
    description: `Coltec's Smart Tools enhance teaching with intuitive features for seamless lesson integration. Engage students with interactive elements and streamline classroom management.`,
    image: "/assets/images/education/endless-whiteboarding-2.jpg",
  },
  {
    heading: "Instant Visualization",
    description: `Coltec's Smart Tools enables text to image and video conversion right from the writing board. This enables easy comprehension of concepts thoughts and ideas.`,
    image: "/assets/images/education/classroom-instant-visualization.jpg",
  },
  {
    heading: "Apps Support",
    description: `Experience latest applications on the smart interactive flat panel with high processing capability on android and windows OS.`,
    image: "/assets/images/education/apps-support.jpg",
  },
  // Add more slides as needed
];
const conslides = [
  {
    heading: "Anti-Glare Display",
    description:
      "Coltec's Anti-Glare Display ensures clear visibility from every angle, reducing eye strain and enhancing the viewing experience in any lighting condition. Teach and learn without distractions.",
    image: "/assets/images/education/anti-glare-display.jpg",
  },
  {
    heading: "Toughened Glass",
    description: `Coltec's Toughened Glass offers exceptional durability and safety, ensuring reliable performance in any classroom setting. Experience unmatched clarity and resilience with our innovative design.`,
    image: "/assets/images/education/hardware-toughened-glass.jpg",
  },
  {
    heading: "Multiple Sizes",
    description: `Choose from a wide variety of sizes starting from 65” up-to 98”. The sizes are designed as per the class seating capacity ranging from small, medium & large classrooms.`,
    image: "/assets/images/education/multiple-sizes.jpg",
  },
  // Add more slides as needed
];
const Education = () => {
  return (
    <>
      <SEO
        title="Education Solutions - Worlds 1st Intelligent Classroom Technology Provider | Coltec Global"
        description="At COLTEC-GLOBAL, we empower teaching and learning with interactive panels that provide curated, subject-specific multimedia content using cutting-edge technology."
        keywords="Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Education Technology Solutions, Interactive Flat Panel Display, Smart Board for Classrooms"
        ogTitle="Education Solutions - Worlds 1st Intelligent Classroom Technology Provider | Coltec Global"
        ogDescription="At COLTEC-GLOBAL, we empower teaching and learning with interactive panels that provide curated, subject-specific multimedia content using cutting-edge technology."
        ogImage="/assets/images/coltec-logo-black.svg"
        ogUrl="https://coltec-global.com/solutions/education"
        canonicalUrl="https://coltec-global.com/solutions/education"
      />
      <div className="bg-gray-900">
        <Header />
        <div className="relative">
          <Banner
            imgSrc="/assets/images/education/banner-img.jpg"
            altText="Background image"
            headerText="Worlds 1st Intelligent Classroom Technology Provider"
            bannerHeight="md:h-dvh h-[85vh]"
            opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
            headerTextClasses="md:text-[37px] text-[29px] text-white max-w-2xl font-medium leading-[1.3]"
            paragraphTextClasses="md:text-base text-sm font-normal mt-3 text-white max-w-xl leading-[1.5]"
            positionClasses="absolute md:bottom-60 bottom-[8rem] left-0 lg:left-32"
            paragraphText="At COLTEC-GLOBAL, we are redefining modern spaces for teaching and learning. By empowering teachers and students with state-of-the-art visual interactive technology, our powerful interactive panels deliver platform-based, curated subject-specific multimedia content."
            paragraphTextTwo="Our patented Voice-AI technology transforms any space into a powerful knowledge powerhouse.​"
          />
          <div className="absolute md:bottom-36 bottom-[3rem] mx-w-7xl lg:px-8 px-6 left-0 lg:left-32 space-x-4 flex">
            <button className="border-white/20 bg-gradient-radial backdrop-blur-md px-5 py-3 border rounded-lg text-white hover:bg-white/5 uppercase text-xs md:text-sm">
              Explore more
            </button>
            <button className="border-white/20 bg-gradient-radial backdrop-blur-md px-5 py-3 border rounded-lg text-white hover:bg-white/5 uppercase md:text-sm text-xs flex items-center">
              Book a demo now
              <img
                alt="arrow-icon"
                className="h-[10px] ml-2"
                src="/assets/images/arrow-white.svg"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="relative group">
            <img
              alt="product-whiteboarding"
              src="/assets/images/education/product-whiteboarding.jpg"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Whiteboarding
              </h3>
              <p className="text-white max-w-xl text-sm">
                Our interactive flat panel simplifies teaching with easy-to-use
                whiteboarding features, making lessons more engaging and
                interactive.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="product-video-teaching"
              src="/assets/images/education/product-video-teaching.jpg"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Video Teaching
              </h3>
              <p className="text-white max-w-xl text-sm">
                Elevate your video lessons with our interactive flat panel,
                offering sharp visuals and seamless integration for a more
                immersive and engaging learning experience.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="product-presentation"
              src="/assets/images/education/product-presentation.jpg"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Presentation
              </h3>
              <p className="text-white max-w-xl text-sm">
                Elevate student presentations with our interactive flat panel,
                offering dynamic visuals and intuitive tools that make
                presenting more engaging and impactful.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="product-all-in-one"
              src="/assets/images/education/product-all-in-one.jpg"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Voice Control
              </h3>
              <p className="text-white max-w-xl text-sm">
                Enhance your teaching experience with voice control, allowing
                hands-free interaction with our display panel for a more fluid
                and efficient classroom environment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <CorporateModule slides={cslides} />
        </div>
      </div>
      <div className="bg-[#DADADA]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <CorporateModule slides={conslides} />
        </div>
      </div>
      <div className="bg-[#f5f5f5]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
          <Testimonials testimonials={testimonials} />
        </div>
      </div>
      <div>
        <Blogs />
      </div>
      <div className="bg-gray-100">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Education;
