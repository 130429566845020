const expoData = [
  {
    id: 1,
    title: "ET Techx Expo - 2024",
    images: [
      {
        src: "/assets/images/media/et-expo-team-4.jpeg",
        alt: "et-expo-team-1",
        class: "row-span-2 col-span-2 h-[405px]",
      },
      {
        src: "/assets/images/media/et-expo-team-2.jpg",
        alt: "et-expo-team-2",
        class: "col-span-2 h-[190px]",
      },
      {
        src: "/assets/images/media/et-expo-team-3.jpeg",
        alt: "et-expo-team-3",
        class: "col-span-1 h-[190px]",
      },
      {
        src: "/assets/images/media/et-expo-team-5.jpeg",
        alt: "et-expo-team-4",
        class: "col-span-1 h-[190px]",
      },
      {
        src: "/assets/images/media/et-expo-team-1.jpeg",
        alt: "et-expo-team-5",
        class: "col-span-2 h-[190px]",
      },
    ],
    description:
      "At India’s second-largest education expo, COLTEC proudly presented QEEB, bridging world-class teaching resources with cutting-edge technology. A showcase where education’s next chapter takes center stage!",
  },
  {
    id: 2,
    title: "Rifaah Expo - 2024",
    images: [
      {
        src: "/assets/images/media/rifaah-expo-team-3.jpg",
        alt: "rifaah-expo-team-1",
        class: "row-span-2 col-span-3 h-[400px]",
      },
      {
        src: "/assets/images/media/rifaah-expo-team-4.jpeg",
        alt: "rifaah-expo-team-2",
        class: "col-span-1 h-[175px]",
      },
      {
        src: "/assets/images/media/rifaah-expo-team-2.jpeg",
        alt: "rifaah-expo-team-3",
        class: "col-span-1 h-[175px]",
      },
      {
        src: "/assets/images/media/rifaah-expo-team-1.jpeg",
        alt: "rifaah-expo-team-4",
        class: "col-span-2 h-[200px]",
      },
    ],
    description:
      "COLTEC proudly showcased its groundbreaking solutions at the Rifah Expo, connecting with educators and tech enthusiasts to redefine the future of learning and collaboration. A platform where innovation met inspiration!",
  },
];

export default expoData;
