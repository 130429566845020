import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// Import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const Testimonials = ({ testimonials }) => {
  return (
    <>
      <div className="text-center mb-8">
        <p className="md:text-[32px] text-[22px]">Our Testimonials</p>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 30,
          depth: 200,
          modifier: 2,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false, // Ensures autoplay continues after user interaction
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
          1201: {
            slidesPerView: 2,
          },
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper testimonial-coverflow"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide
            key={index}
            className="swiper-slide-testimonial-coverflow"
          >
            <div className="relative bg-white p-8 rounded-lg shadow-lg h-full w-full">
              <div className="flex items-center mb-6">
                <img
                  src={testimonial.image}
                  alt={`Testimonial ${index}`}
                  className="w-24 h-24 rounded-full border object-contain mr-4"
                />
                <div>
                  <p className="text-lg font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">
                    {testimonial.designation}
                  </p>
                </div>
              </div>
              <p className="text-gray-700 text-[15px]">{testimonial.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Testimonials;
