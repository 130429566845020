import React from "react";

const ProductSpecifications = ({ specs }) => {
  // Extract all unique labels across different sizes
  const keys = new Set();
  Object.values(specs).forEach((sizeSpecs) => {
    sizeSpecs.forEach(({ label }) => keys.add(label));
  });
  const uniqueKeys = Array.from(keys);

  return (
    <>
      <h2 className="md:text-[32px] text-[22px] mb-5 md:mb-10 lg:text-center">
        Product Specifications
      </h2>

      {/* Display the sizes as headers */}
      <div className="hidden xl:grid xl:grid-cols-5">
        <div className="font-medium text-[12px] border border-l-0 border-r-0 border-gray-300 p-5 pl-0">
          Diagonal Size (inch)
        </div>
        {Object.keys(specs).map((size) => (
          <div
            key={size}
            className="font-medium text-[11px] border border-l-0 border-r-0 border-gray-300 p-5 pl-0"
          >
            {size}
          </div>
        ))}
      </div>

      {/* Display the specification labels and their values */}
      {uniqueKeys.map((label) => (
        <div key={label} className="hidden xl:grid xl:grid-cols-5">
          {/* Label column */}
          <div className="font-medium text-[12px] border-b border-l-0 border-r-0 border-gray-300 p-5 pl-0">
            {label}
          </div>

          {/* Values for each size */}
          {Object.keys(specs).map((size) => {
            const specForSize = specs[size].find(
              (spec) => spec.label === label
            );
            return (
              <div
                key={size}
                className="font-medium text-[11px] border-b border-l-0 border-r-0 border-gray-300 p-5 pl-0"
              >
                {specForSize ? specForSize.value : "N/A"}
              </div>
            );
          })}
        </div>
      ))}
      {/* Card layout for screens less than 1200px */}
      <div className="xl:hidden">
        {Object.keys(specs).map((size) => (
          <div
            key={size}
            className="mb-6 p-4 border border-gray-200 shadow-md rounded-lg"
          >
            <h3 className="font-medium text-[14px] mb-2 border-b pb-2">
              Diagonal Size (inch) {size}
            </h3>
            {uniqueKeys.map((label) => {
              const specForSize = specs[size].find(
                (spec) => spec.label === label
              );
              return (
                <div
                  key={label}
                  className="flex justify-between items-center mb-2 border-b pb-2"
                >
                  {/* Label and Value Side by Side */}
                  <span className="font-medium text-[12px] w-1/2">
                    {label}:
                  </span>
                  <span className="text-[12px] w-1/2 text-right">
                    {specForSize ? specForSize.value : "N/A"}
                  </span>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductSpecifications;
