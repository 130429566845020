import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import expoData from "../utils/expoData";

const Media = () => {
  return (
    <>
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="/assets/images/media-banner-image.jpg"
          altText="banner-img"
          bannerHeight="md:h-dvh h-[60vh]"
          headerText="Celebrating Moments of Excellence"
          opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
          paragraphText="Welcome to the Media page of COLTEC, where we showcase the milestones that define our journey. Explore a collection of moments from prestigious expos, awards, and events that reflect our commitment to innovation and excellence. Dive into the visual story of how COLTEC continues to shape the future of education and technology."
          positionClasses="absolute md:bottom-64 bottom-12 left-0 lg:left-32"
          headerTextClasses="text-[29px] md:text-4xl max-w-2xl text-white font-medium leading-10"
          paragraphTextClasses="text-base text-white max-w-2xl mt-4"
        />
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative space-y-16">
        {expoData.map((expo) => (
          <div key={expo.id} className="md:text-center">
            <h2 className="text-[#383838] md:text-[36px] text-3xl font-normal mb-5">
              {expo.title}
            </h2>
            <p className="text-[#383838] text-base font-normal max-w-2xl mx-auto mb-10">
              {expo.description}
            </p>
            <div className="grid md:grid-cols-5 gap-6">
              {expo.images.map((image, index) => (
                <div className={image.class}>
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="w-full object-cover h-full"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Media;
