import React from "react";

const ImageContentLayout = ({ imageSrc, content, imagePosition }) => {
  return (
    <>
      <div
        className={`flex gap-12 ${
          imagePosition === "left"
            ? "lg:flex-row flex-col"
            : "lg:flex-row-reverse flex-col"
        }`}
      >
        <div className="flex-1" data-aos="fade-right">
          <img
            src={imageSrc}
            alt="Dynamic"
            className="w-full h-auto rounded-lg"
          />
        </div>
        <div
          className="flex-1"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          {content}
        </div>
      </div>
    </>
  );
};

export default ImageContentLayout;
