import React, { useEffect, useRef } from "react";

const ZohoWebform = ({ bodyClassName }) => {
  const iframeRef = useRef(null);
  const iframeUrl = `/form.html`; // The URL of the outer iframe

  useEffect(() => {
    const sendMessageToIframe = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          { type: "ADD_CLASS", className: bodyClassName },
          "*"
        );
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", sendMessageToIframe);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", sendMessageToIframe);
      }
    };
  }, [bodyClassName]);

  return (
    <div>
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        title="outer-iframe"
        className="w-full lg:h-[900px] h-[1120px] search-iframe"
        style={{ border: "none" }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ZohoWebform;
