import React from "react";
import Header from "../components/Header";
import BlogPage from "../components/Blogs";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import ScrollToTopButton from "../components/ScrollTop";
import SEO from "../components/SEO";

const Blogs = () => {
  return (
    <>
      <div className="blogs-page">
        <SEO
          title="Blogs - Insights & Innovations | Coltec Global"
          description="Stay updated with the latest insights, industry trends, and innovations from Coltec Global. Explore our blogs for expert opinions and updates on cutting-edge technology."
          keywords="Coltec Global Blogs, Industry Trends, Technology Innovations, Expert Insights, Latest Updates"
          ogTitle="Blogs - Insights & Innovations | Coltec Global"
          ogDescription="Discover expert opinions, industry trends, and the latest innovations in technology through Coltec Global's blogs."
          ogImage="/assets/images/blogs/blog.jpg" 
          ogUrl="https://coltec-global.com/blogs"
          canonicalUrl="https://coltec-global.com/blogs"
        />
        <Header />
        <div className="mt-12">
          <BlogPage />
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default Blogs;
