import React, { useState } from "react";

const CTA = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="bg-[#30A0E3]">
      <div className="px-6 py-10 sm:px-6 lg:py-14 lg:px-8 max-w-7xl mx-auto">
        <div className="flex md:flex-row flex-col justify-start items-start md:justify-between md:items-center">
          <h2 className="lg:text-[34px] text-[26px] font-medium leading-[1.3] text-white md:max-w-3xl">
            Get the most advanced Visual Interactive Technology Solutions
          </h2>
          <div className="flex items-center justify-center gap-x-6 mt-8 md:mt-0">
            <button
              onClick={openModal}
              className="flex items-center rounded-md bg-white lg:px-6 px-6 py-2 text-sm font-medium text-[#0A78B9] shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Send Enquiry
              <img
                alt="right-arrow"
                src="/assets/images/blue-arrow.svg"
                className="h-2 pl-2"
              />
            </button>
          </div>
        </div>
      </div>
      {/* Modal popup */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 py-6">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={closeModal}
            >
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>
            <div className="bg-[#f1f1f1] rounded-lg overflow-hidden shadow-xl transform transition-all max-w-[770px] w-full z-10">
              <div className="flex items-center justify-between p-4 bg-white">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Contact Form
                </h3>
                <div>
                  <button onClick={closeModal}>
                    <img
                      alt="close-icon"
                      className="w-6"
                      src="/assets/images/close-icon.svg"
                    />
                  </button>
                </div>
              </div>
              <div className="modal-iframe">
                <iframe
                  src="/modal-form.html"
                  title="outer-iframe"
                  className="w-full h-full search-iframe"
                  style={{ border: "none" }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CTA;
