import React from "react";
import { useParams } from "react-router-dom";
import { blogData } from "../utils/blogData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import ScrollToTopButton from "../components/ScrollTop";
import SEO from "../components/SEO";
const BlogDetails = () => {
  const { title } = useParams();
  const blog = blogData.find((b) => b.slug === title);
  return (
    <>
      <div className="blogs-page">
        <SEO
          title={`${blog.title} | Coltec Global`}
          description={blog.metaDescription}
          keywords={blog.keywords}
          ogTitle={blog.title}
          ogDescription={blog.metaDescription}
          ogImage={blog.image}
          ogUrl={`https://coltec-global.com/blogs/${blog.slug}`}
          canonicalUrl={`https://coltec-global.com/blogs/${blog.slug}`}
        />
        <Header />
        <div className="mx-auto max-w-7xl px-6 pt-24 pb-12 lg:px-8 md:pt-28 md:pb-14">
          {blog ? (
            <>
              <div className="mb-12 max-w-3xl">
                <h1 className="text-[30px] font-semibold">{blog.title}</h1>
              </div>
              <img
                alt={blog.alt}
                src={blog.image}
                className="rounded-lg w-full lg:h-[550px] object-cover mb-6"
              />
              <div
                className="prose mt-12"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              ></div>
            </>
          ) : (
            <p>Blog not found</p>
          )}
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default BlogDetails;
