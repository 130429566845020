import React from "react";
import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import CTA from "../../../components/CTA";
import Footer from "../../../components/Footer";
import ScrollToTopButton from "../../../components/ScrollTop";
import ProductSpecification from "../../../components/ProductSpecification";
import productSpecs from "../../../utils/riseSeries";
import ApplicationScenarios from "../../../components/ApplicationScenarios";
import Blogs from "../../../components/Blogs";
import SEO from "../../../components/SEO";
import Testimonials from "../../../components/Testimonials";

const testimonials = [
  {
    image: "/assets/images/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "/assets/images/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "/assets/images/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "/assets/images/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "/assets/images/testimonials/berkadia.jpg",
    name: "Raj Kumar",
    designation: "AV Head",
    description:
      "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
  },
  {
    image: "/assets/images/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "/assets/images/testimonials/edify-education.png",
    name: "Kishore Pandit",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
  {
    image: "/assets/images/testimonials/the-dollar-business.png",
    name: "Ashok Tripathi",
    designation: "IT Head",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
];

const images = [
  {
    url: "/assets/images/products/rise-large-conference-room.jpg",
    name: "Large area Conference Room",
  },
  {
    url: "/assets/images/products/rise-corporate-training-room.jpg",
    name: "Corporate Training Room",
  },
  {
    url: "/assets/images/products/rise-conference-room.jpg",
    name: "Conference Room",
  },
  {
    url: "/assets/images/products/rise-classroom.jpg",
    name: "Class Room",
  },
  {
    url: "/assets/images/products/rise-huddle-room.jpg",
    name: "Huddle Room",
  },
];

const RiseSeries = () => {
  return (
    <>
      <div className="rise-series">
        <SEO
          title="Rise Series - Interactive Flat Panel | Revolutionize Collaboration and Learning | Coltec Global"
          description="Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces."
          keywords="Rise Series, Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Interactive Flat Panel Display, Smart Board for Classrooms, Education Technology Solutions, Best Interactive Flat Panel for Schools, Touch Screen Display for Collaborative Learning, Interactive Smart Board for Online Teaching, High-Resolution Interactive Display for Business Meetings, Top Interactive Flat Panels for Classrooms, Multi-Touch Capability Interactive Panel, Zero Bonding Touch Technology Display, Integrated Software Solutions for Teaching, Interactive LED Panel with Low Power Consumption, Durable Interactive Display with Anti-Glare Technology, Interactive Panels for Hybrid Classrooms, Interactive Whiteboard for Conference Rooms, Digital Signage and Interactive Displays for Offices, Interactive Flat Panels for Remote Learning, Educational Interactive Touch Screen Panels, Interactive Flat Panel Supplier in India, Smart Classroom Displays for Indian Schools"
          ogTitle="Interactive Flat Panel | Revolutionize Collaboration and Learning | Coltec Global"
          ogDescription="Elevate classroom learning with the Rise Series Interactive Flat Panel, featuring advanced technology for improved performance and seamless interaction."
          ogImage="/assets/images/products/rise-banner-image.jpg"
          ogUrl="https://coltec-global.com/products/interactive-flat-panel/rise-series"
          canonicalUrl="https://coltec-global.com/products/interactive-flat-panel/rise-series"
        />
        <Header />
        <div>
          <Banner
            imgSrc="/assets/images/products/rise-banner-image.jpg"
            altText="Background image"
            imgHeight="object-none rise-object md:h-full w-full h-[45vh]"
            bannerHeight="h-[45vh] md:h-dvh"
            opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-liner-opacity z-0"
            headerTextClasses="md:text-[44px] text-[29px] font-semibold not-italic text-white leading-2 md:leading-[48px]"
            paragraphTextClasses="md:text-[21px] text-base not-italic font-normal md:leading-[30px] mt-4 text-white max-w-[664px]"
          />
          <div className="bg-linear-grd">
            <div className="px-6 lg:px-8 lg:py-7 py-7 max-w-5xl m-auto">
              <div
                className="grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 xl:gap-0 p-6 bg-white rounded-[32px] shadow-rise"
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
              >
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24 md:mb-0 mb-6">
                    <img
                      alt="color-wheel"
                      src="/assets/images/products/pink-array-mic-icon.svg"
                      className="mb-8"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Built-in 8
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      microphone array
                    </p>
                  </div>
                </div>
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24 md:mb-0 mb-6">
                    <img
                      alt="intelligence"
                      src="/assets/images/products/intelligence.svg"
                      className="mb-6 h-9"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Intelligent
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      touch point
                    </p>
                  </div>
                </div>
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24">
                    <img
                      alt="dual"
                      src="/assets/images/products/dual.svg"
                      className="h-8 mb-6"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Dual
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      stereo speakers
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center flex-col h-24">
                    <img
                      alt="dual-os"
                      src="/assets/images/products/dual-os.svg"
                      className="mb-4"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Dual
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Operating Systems
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grd-linear">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-left"
            >
              <p className="text-2xl font-medium mb-5">
                Ultra-Clear 4K Display
              </p>
              <p className="text-sm font-normal">
                Experience stunning 4K clarity and vibrant 400-nit brightness
                from any angle. With toughened glass protection, our display
                delivers unmatched durability and breathtaking visuals—perfect
                for corporate & education spaces.
              </p>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mt-8">
              <div data-aos="fade-right">
                <img
                  alt="rise-ultra-clear-4k-display"
                  src="/assets/images/products/rise-ultra-clear-4k-display.png"
                />
              </div>
              <div
                className="md:block grid grid-cols-2 lg:pt-[53px]"
                data-aos="fade-left"
              >
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    4K
                  </p>
                  <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                    100% NTSC Colour Gamut Display
                  </p>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    400 nits
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Brightness
                  </span>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    178°
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Viewing Angle
                  </span>
                </div>
                <div className="md:text-right">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    Toughened
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Glass Screen
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-down"
            >
              <p className="text-2xl font-medium mb-5">
                Precision Infrared Touch Technology
              </p>
              <p className="text-sm font-normal">
                Experience flawless interaction with our advanced infrared touch
                technology, featuring 20 touch points for seamless
                collaboration. With a touch precision of ±1mm, every tap and
                swipe is incredibly accurate, ensuring a responsive and
                intuitive experience for all users.
              </p>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mt-8">
              <div data-aos="fade-right">
                <img
                  alt="rise-precision-infrared-touch-tech"
                  src="/assets/images/products/rise-precision-infrared-touch-tech.png"
                />
              </div>
              <div
                className="md:block grid grid-cols-2 lg:pt-[45px]"
                data-aos="fade-left"
              >
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    Infrared
                  </p>
                  <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                    Touch Technology
                  </p>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    20
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Touch Points
                  </span>
                </div>
                <div className="md:text-right">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    ±1mm
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Touch Precision
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grd-linear">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-right"
              data-aos-offset="500"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-2xl font-medium mb-5">
                Powerful Performance and Connectivity
              </p>
              <p className="text-sm font-normal">
                Unleash the power of an Octa-Core Cortex processor combined with
                up to 12GB RAM and 256GB ROM for lightning-fast performance.
                With versatile ports and dual OS support (Android 14 and
                Windows), seamlessly switch between operating systems and
                connect all your devices for a truly dynamic experience.
              </p>
            </div>
            <div className="grid grid-cols-12 mt-10">
              <div className="col-span-12">
                <img
                  className="w-full"
                  alt="rise-powerful-performance-connectivity"
                  src="/assets/images/products/rise-powerful-performance-connectivity.jpg"
                />
              </div>
              <div className="col-span-12 mt-10">
                <div className="flex justify-between flex-wrap gap-8">
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-pink-grd text-[30px] font-semibold not-italic">
                      Octa-Core
                    </p>
                    <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                      Processor
                    </p>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      12/256 GB
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Upto RAM/ROM
                    </span>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      Dual
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Operating Systems
                    </span>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      15
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Connection Ports
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <ApplicationScenarios images={images} />
          </div>
        </div>
        <div className="px-6 lg:px-8 lg:pb-14 pb-10 max-w-7xl m-auto">
          <ProductSpecification specs={productSpecs} />
        </div>
        <div className="bg-[#f5f5f5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <Testimonials testimonials={testimonials} />
          </div>
        </div>
        <div>
          <Blogs />
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default RiseSeries;
